import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import fileDownload from 'js-file-download';

import { axiosProtectedAPI, axiosPublicAPI } from '../http/AxiosInstance';

function Events() {

    const [events, setEvents] = useState([]);
    const history = useHistory();

    useEffect(() => {
        axiosPublicAPI.get('/events/v1/event')
        .then(respose => setEvents(respose.data));
    }, []);

    function handleDownload(eventId, eventName) {
        axiosProtectedAPI.get(`/admin/events/v1/event/${eventId}/users/download`, { responseType: 'blob' })
            .then(response => fileDownload(response.data, `bookings-${eventName}.xls`));
    }

    return (
        <Container className="pt-5">
            <Row>
                <Col>
                    <h2 className="text-center">Sessions</h2>
                </Col>
            </Row>
            <Row className={"pb-5"}>
                <Col>
                    <Button className="theme float-right" onClick={() => history.push("/event-detail/create")}>Create Session</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table striped bordered hover responsive >
                        <thead>
                            <th>Session</th>
                            <th>Bookings</th>
                        </thead>
                        <tbody>
                            {events.map((event, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Link to={{ pathname: "/event-detail/view", state: { eventId: event.id } }}>{event.name}</Link>
                                        </td>
                                        <td>
                                            <Button className="theme" onClick={() => handleDownload(event.id, event.name)}>Download</Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default Events;