import React, { useState, useContext, useEffect } from 'react';
import { Alert, Form, Container, Button, Row, Col } from 'react-bootstrap'
import { axiosPublicAPI } from "./http/AxiosInstance";
import { Redirect } from 'react-router-dom';

function BookingForm() {

    const EVENT_URL = '/events/v1/event';
    const BOOKING_URL = '/eventusers/v1/eventuser';

    const INITIAL_STATE = {
        eventId: '',
        name: '',
        staffId: '',
        email: '',
        department: '',
        mobileNumber: '',
        sats: false,
        vaccinated: false
    };

    const [form, setForm] = useState(INITIAL_STATE);
    const [activeEvents, setActiveEvents] = useState([]);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitFailure, setSubmitFailure] = useState(false);
	const [submitDuplicate, setSubmitDuplicate] = useState(false);
    const [makingAPICall, setMakingAPICall] = useState(false);
    const[submitNonSatsFailure,setSubmitNonSatsFailure]= useState(false);
    const[submitVaccinatedFailure,setsubmitVaccinatedFailure]= useState(false);
    useEffect(() => {
        setMakingAPICall(true);
        axiosPublicAPI.get(EVENT_URL)
            .then(respose => {
                setMakingAPICall(false);
                const events = respose.data.filter(event => event.active);
                setActiveEvents(events);
            })
            .catch(error => setMakingAPICall(false));
    }, []);

    function handleClear() {
        setForm(INITIAL_STATE);
    }

    function handleSubmit(e) {
        setSubmitNonSatsFailure(false);
        setsubmitVaccinatedFailure(false);
        e.preventDefault();
        const activeEvent = activeEvents.find(event => event.id === form.eventId);
        console.log('active event'+ activeEvent);
        if(!activeEvent.allow&&(form.sats==null || !form.sats)){
            setSubmitNonSatsFailure(true);
            return;

        }else if(form.vaccinated==null || !form.vaccinated){

            setsubmitVaccinatedFailure(true);
            return;
        }
        setMakingAPICall(true);
        axiosPublicAPI.post(BOOKING_URL, form)
            .then(respose => {
                setMakingAPICall(false);
                setSubmitSuccess(true);
            })
            .catch(err => {
				console.log(err.response);

                setMakingAPICall(false)
				if(err.response.status==409){
					
					setSubmitDuplicate(true);
					
				}else{
					
					setSubmitFailure(true);
				}				
                
				
            });
    }

    if (makingAPICall) {
        return (
            <div className="d-flex justify-content-center text-dark">
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    } else if (!activeEvents.length) {
        return (
            <Container>
                <Row>
                    <Col>
                        <Alert variant="danger" className="mt-5">
                            <Alert.Heading>Friday Prayer Booking Closed</Alert.Heading>
                            <p>
                            Next booking for SATS Staff will commence from Tuesday, 12pm.
							</p>
							<p>
							Next booking for Non-SATS Staff (SATS Tenants, Airlines & Cargo Agents) will commence from Wednesday, 3pm.
                            </p>														
                        </Alert>
                    </Col>
                </Row>
            </Container>
        );
    }

    if (submitSuccess) {
        const activeEvent = activeEvents.filter(event => event.id === form.eventId)
        return <Redirect to={{
            pathname: "/book/success",
            state: { form: { ...form, eventName: activeEvent.length ? activeEvent[0].name : '' } }
        }} />;
    }
    if (submitNonSatsFailure) {
        return (
            <Container>
                <Row>
                    <Col>
                        <Alert variant="danger" className="mt-5">
                            <Alert.Heading>Booking Closed for Non Sats Staff</Alert.Heading>
                            <p>
                            Next Booking for Non SATS Staff will commence from Wednesday, 3pm.
                        </p>
                        </Alert>
                    </Col>
                </Row>
            </Container>
        );
    }
    if (submitVaccinatedFailure) {
        return (
            <Container>
                <Row>
                    <Col>
                        <Alert variant="danger" className="mt-5">
                            <Alert.Heading>Booking Closed for Non Vaccinated Staff</Alert.Heading>
                            <p>
                            Booking are open only for Fully Vaccinated Staff.
                        </p>
                        </Alert>
                    </Col>
                </Row>
            </Container>
        );
    }
    if (submitFailure) {
        return (
            <Container>
                <Row>
                    <Col>
                        <Alert variant="danger" className="mt-5">
                            <Alert.Heading>Booking Closed</Alert.Heading>
                            <p>
                                Next booking will commence from Tuesday, 12pm.
                        </p>
                        </Alert>
                    </Col>
                </Row>
            </Container>
        );
    }
    if (submitDuplicate) {
        return (
            <Container>
                <Row>
                    <Col>
                        <Alert variant="danger" className="mt-5">
                            <Alert.Heading>Duplicate Booking</Alert.Heading>
                            <p>
                                User already made a reservation.
                        </p>
                        </Alert>
                    </Col>
                </Row>
            </Container>
        );
    }
    return (
        <Container>
            <Row>
                <Col>
                    <Alert variant="warning" className="mt-5">
                        <div>
                            <ul>
                                <li>Booking is open only for Fully Vaccinated Staff.</li>
                                <li>Booking is open for SATS Staff from Tuesday, 12pm onwards.</li>
								<li>Booking for SATS Tenants, Airlines & Cargo Agents from Wednesday, 3pm onwards.</li>								
                                <li>Booking must be made using full name, staff number, Company email address</li>
                                <li>Only one booking can be made per transaction</li>
                                <li>Bookings are non-transferrable</li>
                            </ul>
                            <strong>Please ensure the following are complied with before arriving at Musolla:</strong>
                            <ul className="mt-1">
                                <li>Please bring along your Staff ID/Airport Pass.</li>
                                <li>Bring prayer mat and wear a mask</li>
                                <li>Come to Musolla with Wudhu'</li>
                            </ul>
                        </div>
                    </Alert>
                </Col>
            </Row>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group controlId="eventId" className="required">
                            <Form.Label className="font-weight-bold control-label">Session</Form.Label>
                            <Form.Control as="select" required value={form.eventId} onChange={e => setForm({ ...form, eventId: e.target.value })}>
                                <option value="" disabled>Choose a session</option>
                                {
                                    activeEvents.map((event, index) => <option key={index} value={event.id} disabled={event.maxCount-event.count <= 0}>{`${event.name}  (${event.maxCount-event.count > 0 ? event.maxCount-event.count : "Not"} available)`}</option>)
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="name" className="required">
                            <Form.Label className="font-weight-bold control-label">Full Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Full Name" required value={form.name} onChange={e => setForm({ ...form, name: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="staffId" className="required">
                            <Form.Label className="font-weight-bold control-label">Staff ID / Airport Pass No.</Form.Label>
                            <span className="text-muted small">(8 digits)</span>
                            <Form.Control type="text" placeholder="Enter Staff ID / Airport Pass No." required value={form.staffId}
                                pattern="\d{8,8}" title="8 digits Staff ID"
                                onChange={e => setForm({ ...form, staffId: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="email" className="required">
                            <Form.Label className="font-weight-bold control-label">SATS Email Address</Form.Label>
                            <Form.Control type="email" placeholder="Enter SATS Email Address" required value={form.email} onChange={e => setForm({ ...form, email: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="department" className="required">
                            <Form.Label className="font-weight-bold control-label">Department</Form.Label>
                            <Form.Control type="text" placeholder="Enter Department" required value={form.department} onChange={e => setForm({ ...form, department: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="mobileNumber" className="required">
                            <Form.Label className="font-weight-bold control-label">Mobile Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter Mobile Number" required value={form.mobileNumber}
                                pattern="\d{8,8}" title="8 digits mobile number"
                                onChange={e => setForm({ ...form, mobileNumber: e.target.value })} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                <Col>
                <Form.Group controlId="sats">
                <Form.Label column sm="2" className="font-weight-bold control-label">Sats Staff</Form.Label>
                    <Col sm='10'>
                        <Form.Check
                            id={'sats'}
                            type={'switch'}
                            label={''}
                            value={form.sats}
                            onChange={e => setForm({ ...form, sats: e.target.checked })}
                        />
                        
                    </Col>
                    </Form.Group>
                    </Col>
                </Row>
                <Row>
                <Col>
                <Form.Group controlId="vaccinated">
                <Form.Label column sm="2" className="font-weight-bold control-label">Fully Vaccinated</Form.Label>
                    <Col sm='10'>
                        <Form.Check
                            id={'vaccinated'}
                            type={'switch'}
                            label={''}
                            value={form.vaccinated}
                            onChange={e => setForm({ ...form, vaccinated: e.target.checked })}
                        />
                        
                    </Col>
                    </Form.Group>
                    </Col>
                </Row>
                <Row className="md-2">
                    <Col>
                        <Button type="submit" block className="theme">Submit for Booking</Button>
                    </Col>
                    <Col>
                        <Button variant="secondary" block onClick={handleClear}>Clear</Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}

export default BookingForm;
