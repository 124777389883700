import React, { useState, useContext } from 'react';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import { Navbar, Container, Row, Col, Alert, NavDropdown } from 'react-bootstrap';

import BookingForm from './components/BookingForm';
import BookingSuccess from './components/BookingSuccess';
import AppContext from './AppContext';
import './App.css';
import Login from './components/admin/Login';
import Events from './components/admin/Events';
import EventDetail from './components/admin/EventDetail';
import { AuthContext } from './components/auth/AuthContextProvider';
import BookingCancel from './components/BookingCancel';
import ProtectedRoute from './components/auth/ProtectedRoute';
import ChangePassword from './components/admin/ChangePassword';

function App() {

  const { user, logout } = useContext(AuthContext);
  const [alert, setAlert] = useState(undefined);

  function handleLogout() {
    logout();
  }

  return (
    <AppContext.Provider value={{ setAlert }}>
      <BrowserRouter>
        <Navbar variant="dark" className="theme">
          <Navbar.Brand href="/">Musolla@Cargo Friday Prayer Booking</Navbar.Brand>
          {user &&
            <Navbar.Collapse className="justify-content-end">
              <NavDropdown title={user.username} id="basic-nav-dropdown">
                <NavDropdown.Item><Link to="/change-password" className="text-dark">Change Password</Link></NavDropdown.Item>
                <NavDropdown.Item><Link to="/login" onClick={handleLogout} className="text-dark">Logout</Link></NavDropdown.Item>
              </NavDropdown>
              {/* <Link to="/login" onClick={handleLogout} className="text-light">Logout</Link> */}
            </Navbar.Collapse>
          }
        </Navbar>
        <Container className="content-body mt-5">
          {alert &&
            <Row>
              <Col>
                <Alert variant={alert.variant} show={!!alert} onClose={() => setAlert(undefined)} dismissible className="mt-5">
                  <Alert.Heading>{alert.heading}</Alert.Heading>
                  <p>{alert.message}</p>
                </Alert>
              </Col>
            </Row>}
          <Switch>
            <ProtectedRoute exact path="/">
              <Events />
            </ProtectedRoute>
            <ProtectedRoute exact path="/event-detail/:mode">
              <EventDetail />
            </ProtectedRoute>
            <ProtectedRoute exact path="/change-password">
              <ChangePassword />
            </ProtectedRoute>
            <Route exact path="/login" component={Login} />
            <Route exact path="/book" component={BookingForm} />
            <Route exact path="/book/success" render={(props) =>
              <BookingSuccess {...props} />}
            />
            <Route exact path="/cancel-booking/:id" component={BookingCancel} />
          </Switch>
        </Container>
        <div className="mt-5" />
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
// export const refreshAuthToken = () => refreshAuthToken;
