import React, { useState, useContext } from 'react';
import { Form, Container, Row, Col, Button, Alert, Card } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../auth/AuthContextProvider';

function Login() {

    const {login} = useContext(AuthContext);
    const [form, setForm] = useState({ username: '', password: '', grant_type: 'password' });
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [loginFailure, setLoginFailure] = useState(false);

    function handleLogin(e) {
        e.preventDefault();
        login(form.username, form.password)
            .then(response => {
                setLoginSuccess(true);
            })
            .catch(err => {
                setLoginFailure(true);
            });
    }

    if (loginSuccess) {
        return <Redirect to="/" />;
    }

    return (
        <Container className="pt-5">
            {loginFailure &&
                <Row>
                    <Col>
                        <Alert variant="danger">Either username or password is incorrect.</Alert>
                    </Col>
                </Row>
            }
            <Card>
                <Card.Header className="font-weight-bold text-center">Login</Card.Header>
                <Card.Body>
                    <Form onSubmit={handleLogin}>
                        <Row>
                            <Col>
                                <Form.Group controlId="username" className="required">
                                    <Form.Label className="font-weight-bold control-label">Username</Form.Label>
                                    <Form.Control type="email" placeholder="Enter Username" required value={form.username} onChange={e => setForm({ ...form, username: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="password" className="required">
                                    <Form.Label className="font-weight-bold control-label">Password</Form.Label>
                                    <Form.Control type="password" placeholder="Enter Passsword" required value={form.password} onChange={e => setForm({ ...form, password: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button type="submit" block className="theme">Login</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default Login;