import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Table, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { setMinutes, setHours } from "date-fns";

import AppContext from '../../AppContext';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { axiosProtectedAPI, axiosPublicAPI } from '../http/AxiosInstance';

function EventDetail(props) {

    const EVENT_ADMIN_PATH = "/admin/events/v1/event";

    const { setAlert } = useContext(AppContext);
    const [formData, setFormData] = useState({
        eventDate: new Date(),
        fromTime: setMinutes(setHours(new Date(), 0), 0),
        session: 'Session 1',
        maxCount: 50,
        restrictPreviousDay: 1,
        active: true,
        allow: false
    });
    const [eventUsers, setEventUsers] = useState([]);
    const [deleteBookingId, setDeleteBookingId] = useState(undefined);
    const { state: { eventId } = {} } = useLocation();
    const { mode } = useParams();
    const history = useHistory();

    useEffect(() => {
        switch (mode) {
            case 'edit':
            case 'view':
                if (eventId) {
                    axiosProtectedAPI.get(`${EVENT_ADMIN_PATH}/${eventId}`)
                        .then(response => {
                            setFormData(constructFormData(response.data));
                        })
                        .catch(error => {
                            console.log(error);
                            setAlert({ variant: "danger", heading: "Opps, Something went wrong !!", message: "An error occured while fetching session. Please try again later" });
                        });
                    fetchEventUsers();
                }
                break;
        }
    }, [mode, eventId]);

    function fetchEventUsers() {
        axiosProtectedAPI.get(`${EVENT_ADMIN_PATH}/${eventId}/users`)
            .then(response => {
                setEventUsers(response.data);
            })
            .catch(error => {
                console.log(error);
                setAlert({ variant: "danger", heading: "Opps, Something went wrong !!", message: "An error occured while fetching bookings of session. Please try again later" });
            });
    }


    function handleSubmit(e) {
        e.preventDefault();
        const event = constructEvent();
        switch (mode) {
            case "create":
                createEvent(event)
                break;
            case 'edit':
                updateEvent(event);
                break;
        }
    }

    function constructFormData(event) {
        return {
            eventDate: new Date(event.eventDate),
            fromTime: new Date(event.startDateTime),
            toTime: new Date(event.endDateTime),
            session: event.session,
            maxCount: event.maxCount,
            restrictPreviousDay: event.restrictPreviousDay,
            active: event.active,
            allow: event.allow
        };
    }

    function createEvent(event) {
        axiosProtectedAPI.post(`${EVENT_ADMIN_PATH}`, event,)
            .then(response => {
                const eventId = response.data.id;
                setAlert({ variant: "success", heading: "Success !!", message: "Successfully created session" });
                history.push({ pathname: "/event-detail/view", state: { eventId: eventId } });
            })
            .catch(error => {
                console.log(error);
                setAlert({ variant: "danger", heading: "Opps, Something went wrong !!", message: "An error occured while creating session. Please try again later" });
            });
    }

    function updateEvent(event) {
        axiosProtectedAPI.put(`${EVENT_ADMIN_PATH}/${eventId}`, event)
            .then(response => {
                setAlert({ variant: "success", heading: "Success !!", message: "Successfully updated session" });
                history.push({ pathname: "/event-detail/view", state: { eventId: eventId } });
            })
            .catch(error => {
                console.log(error);
                setAlert({ variant: "danger", heading: "Opps, Something went wrong !!", message: "An error occured while updating session. Please try again later" });
            });
    }

    function padZero(value) {
        return value < 10 ? `0${value}` : value;
    }

    function constructEvent() {
        const formattedFromTime = `${padZero(formData.fromTime.getHours())}:${padZero(formData.fromTime.getMinutes())}`;
        const formattedtoTime = `${padZero(formData.toTime.getHours())}:${padZero(formData.toTime.getMinutes())}`;
        return {
            session: formData.session,
            eventDate: formData.eventDate,
            startTime: formattedFromTime,
            endTime: formattedtoTime,
            maxCount: formData.maxCount,
            restrictPreviousDay: formData.restrictPreviousDay,
            active: formData.active,
            allow: formData.allow
        };
    }

    function handleDelete() {
        axiosProtectedAPI.delete(`${EVENT_ADMIN_PATH}/user/${deleteBookingId}`)
            .then(response => {
                setAlert({ variant: "success", heading: "Booking Deletion", message: "Booking is deleted successfully" });
                fetchEventUsers();
            }).catch(error => setAlert({ variant: "danger", heading: "Booking Deletion", message: "Sorry, we are unable to delete booking. Please try agin later" }));
        setDeleteBookingId(undefined);
    }

    return (
        <Container className="pt-5">
            <Row>
                <Col>
                    <h3 className="text-center text-capitalize text-dark">{mode} Session</h3>
                </Col>
            </Row>
            <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} controlId="session" className="required">
                    <Form.Label column sm="2" className="font-weight-bold control-label">Session Date</Form.Label>
                    <Col sm='10'>
                        <Form.Control as={DatePicker}
                            required
                            minDate={new Date()}
                            selected={formData.eventDate}
                            onChange={date => setFormData({ ...formData, eventDate: date })}
                            readOnly={mode !== 'create'}
                            dateFormat="dd/MM/yyyy"
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="session" className="required">
                    <Form.Label column sm="2" className="font-weight-bold control-label">From</Form.Label>
                    <Col sm='10'>
                        <Form.Control as={DatePicker}
                            required
                            selected={formData.fromTime}
                            onChange={date => setFormData({ ...formData, fromTime: date })}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="From"
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            readOnly={mode === 'view'}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="session" className="required">
                    <Form.Label column sm="2" className="font-weight-bold control-label">To</Form.Label>
                    <Col sm='10'>
                        <Form.Control as={DatePicker}
                            autoComplete="off"
                            required
                            selected={formData.toTime}
                            onChange={date => setFormData({ ...formData, toTime: date })}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="To"
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            minTime={formData.fromTime ? setMinutes(formData.fromTime, formData.fromTime.getMinutes() + 1) : setMinutes(setHours(new Date(), 0), 0)}
                            maxTime={setMinutes(setHours(new Date(), 23), 59)}
                            readOnly={mode === 'view'}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="session" className="required">
                    <Form.Label column sm="2" className="font-weight-bold control-label">Session Name</Form.Label>
                    <Col sm='10'>
                        <Form.Control type="text"
                            placeholder="Enter session name"
                            required
                            readOnly={mode === 'view'}
                            value={formData.session}
                            onChange={e => setFormData({ ...formData, session: e.target.value })} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="session" className="required">
                    <Form.Label column sm="2" className="font-weight-bold control-label">No of people</Form.Label>
                    <Col sm='10'>
                        <Form.Control type="number"
                            placeholder="Enter max people allowed"
                            required
                            readOnly={mode === 'view'}
                            value={formData.maxCount}
                            onChange={e => setFormData({ ...formData, maxCount: e.target.value })} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="session" className="required">
                    <Form.Label column sm="2" className="font-weight-bold control-label">Restriction Days</Form.Label>
                    <Col sm='10'>
                        <Form.Control type="number"
                            placeholder="Enter restriction days"
                            required
                            readOnly={mode === 'view'}
                            value={formData.restrictPreviousDay}
                            onChange={e => setFormData({ ...formData, restrictPreviousDay: e.target.value })} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="session" className="required">
                    <Form.Label column sm="2" className="font-weight-bold control-label">Active</Form.Label>
                    <Col sm='10'>
                        <Form.Check
                            id={'active'}
                            type={'switch'}
                            label={''}
                            disabled={mode === 'view'}
                            checked={formData.active}
                            onChange={e => setFormData({ ...formData, active: e.target.checked })}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="session" className="required">
                    <Form.Label column sm="2" className="font-weight-bold control-label">Allow Non-Sats</Form.Label>
                    <Col sm='10'>
                        <Form.Check
                            id={'allow'}
                            type={'switch'}
                            label={''}
                            disabled={mode === 'view'}
                            checked={formData.allow}
                            onChange={e => setFormData({ ...formData, allow: e.target.checked })}
                        />
                    </Col>
                </Form.Group>
                {mode === 'create' &&
                    <Row className="md-2">
                        <Col>
                            <Button type="submit" block className="theme">Create</Button>
                        </Col>
                        <Col>
                            <Button variant="secondary" block onClick={() => history.push("/")}>Cancel</Button>
                        </Col>
                    </Row>
                }
                {mode === 'edit' &&
                    <Row className="md-2">
                        <Col>
                            <Button type="submit" block className="theme">Save</Button>
                        </Col>
                        <Col>
                            <Button variant="secondary" block onClick={() => history.push({ pathname: "/event-detail/view", state: { eventId: eventId } })}>Cancel</Button>
                        </Col>
                    </Row>
                }
                {mode === 'view' &&
                    <Row className="md-2">
                        <Col>
                            <Button type="button" block className="theme" onClick={() => history.push({ pathname: "/event-detail/edit", state: { eventId: eventId } })}>Edit</Button>
                        </Col>
                        <Col>
                            <Button variant="secondary" block onClick={() => history.push("/")}>Cancel</Button>
                        </Col>
                    </Row>
                }
            </Form>
            {mode === 'view' &&
                <Row className="pt-5">
                    <Col>
                        <Table striped bordered hover responsive >
                            <thead>
                                <th>Name</th>
                                <th>Department</th>
                                <th>Staff ID / Airport Pass No.</th>
                                <th>Email</th>
                                <th>Mobile Number</th>
                                <th>Sats Employee</th>
                            </thead>
                            <tbody>
                                {eventUsers.map((eventUser, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {eventUser.name}
                                            </td>
                                            <td>
                                                {eventUser.department}
                                            </td>
                                            <td>
                                                {eventUser.staffId}
                                            </td>
                                            <td>
                                                {eventUser.email}
                                            </td>
                                            <td>
                                                {eventUser.mobileNumber}
                                            </td>
                                            <td>
                                                {eventUser.sats}
                                            </td>
                                            <td>
                                                <Button className="theme" onClick={() => setDeleteBookingId(eventUser.bookingId)}>Delete</Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            }
            <Modal show={!!deleteBookingId} onHide={() => setDeleteBookingId(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Booking Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete the booking?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteBookingId(undefined)}>No</Button>
                    <Button variant="primary" onClick={handleDelete}>Yes, Delete</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default EventDetail;