import React, { useState, useContext } from 'react';
import { Form, Container, Row, Col, Button, Card } from 'react-bootstrap';
import AppContext from '../../AppContext';
import { AuthContext } from '../auth/AuthContextProvider';
import { axiosProtectedAPI } from '../http/AxiosInstance';

function ChangePassword() {

    const { logout } = useContext(AuthContext);
    const { setAlert } = useContext(AppContext);
    const [form, setForm] = useState({ oldPassword: '', newPassword: '', retypedPassword: '' });
    const [retypedPasswordError, setRetypedPasswordError] = useState(false);

    function handleChangePassword(e) {
        e.preventDefault();
        if (form.newPassword !== form.retypedPassword) {
            setRetypedPasswordError(true);
            return;
        } else {
            setRetypedPasswordError(false);
            axiosProtectedAPI.post("/admin/changepassword", form)
            .then(response => {
                setAlert({ variant: "success", heading: "Password Updated", message: "Your password is updated successfully. Please login with your new password" });
                logout();
            })
            .catch(error => setAlert({ variant: "danger", heading: "Opps, Something went wrong !!", message: "Password updated failed. Please check your current password and try again" }));
        }
    }

    return (
        <Container className="pt-5">
            <Card>
                <Card.Header className="font-weight-bold text-center">Change Password</Card.Header>
                <Card.Body>
                    <Form onSubmit={handleChangePassword}>
                        <Row>
                            <Col>
                                <Form.Group controlId="oldPassword" className="required">
                                    <Form.Label className="font-weight-bold control-label">Current Password</Form.Label>
                                    <Form.Control type="password" placeholder="Enter Current Password" required value={form.oldPassword}
                                        onChange={e => setForm({ ...form, oldPassword: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="newPassword" className="required">
                                    <Form.Label className="font-weight-bold control-label">New Password</Form.Label>
                                    <Form.Control type="password" placeholder="Enter New Password" required value={form.newPassword}
                                        pattern="^.{8,20}$" title="Must be 8-20 characters" onChange={e => setForm({ ...form, newPassword: e.target.value })} />
                                    <Form.Text id="passwordHelpBlock" muted>Your password must be 8-20 characters long</Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="retypedPassword" className="required">
                                    <Form.Label className="font-weight-bold control-label">Retype Password</Form.Label>
                                    <Form.Control type="password" placeholder="Retype New Password" required value={form.retypedPassword} onChange={e => setForm({ ...form, retypedPassword: e.target.value })} />
                                    {retypedPasswordError && <Form.Text id="retypeFeedback" className="text-danger">Please check, dosen't match with the new password</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button type="submit" block className="theme">Change Passsword</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default ChangePassword;