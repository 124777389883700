import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from '../auth/AuthContextProvider';

function ProtectedRoute({children, ...rest}) {

    const {user} = useContext(AuthContext);

    return(
        <Route {...rest} render={props => { 
            return user ? children : <Redirect to="/login" />}} />
    );
}
export default ProtectedRoute;