import axios from 'axios';

const axiosProtectedAPI = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const axiosPublicAPI = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

export { axiosProtectedAPI, axiosPublicAPI };

  