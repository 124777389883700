import React from 'react';
import { Alert, Container, Row, Col } from 'react-bootstrap';

function BookingSuccess(props) {
    const DEFAULT_FORM = {
                eventId: '',
                eventName: '',
                name: '',
                staffId: '',
                email: '',
                department: '',
                mobileNumber: ''
            };
    const form = props?.location?.state?.form ? props.location.state.form : DEFAULT_FORM;

    return (
        <Container>
            <Row>
                <Col>
                    <Alert variant="success" className="mt-5">
                        <Alert.Heading>Successful Booking</Alert.Heading>
                        <p>
                            Your reservation is succesfull and a confirmation email is sent to your given email address.
                        </p>
                    </Alert>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs={12} md={2}>
                    <strong>Session</strong>
                </Col>
                <Col xs={12} md={3}>
                    {form.eventName}
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs={12} md={2}>
                    <strong>Full Name</strong>
                </Col>
                <Col xs={12} md={3}>
                    {form.name}
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs={12} md={2}>
                    <strong>Staff ID</strong>
                </Col>
                <Col xs={12} md={3}>
                    {form.staffId}
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs={12} md={2}>
                    <strong>Email Address</strong>
                </Col>
                <Col xs={12} md={3}>
                    {form.email}
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs={12} md={2}>
                    <strong>Department</strong>
                </Col>
                <Col xs={12} md={3}>
                    {form.department}
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs={12} md={2}>
                    <strong>Mobile Number</strong>
                </Col>
                <Col xs={12} md={3}>
                    {form.mobileNumber}
                </Col>
            </Row>
        </Container>
    );
}

export default BookingSuccess;