import { axiosProtectedAPI, axiosPublicAPI } from "../http/AxiosInstance";

function getUser() {
    let currUser = JSON.parse(localStorage.getItem("user"));
    return currUser;
};

function login(username, password) {
    let loginFormData = new FormData();
    loginFormData.append("username", username);
    loginFormData.append("password", password);
    loginFormData.append("grant_type", "password");
    return axiosPublicAPI({
        method: 'POST', url: "/oauth/token", data: loginFormData,
        headers: { 'Authorization': 'Basic c2RmZnM3ODgyM2Fzc2Y4Nzg4c2RmNzg6c2VjcmV0', 'content-type': 'application/x-www-form-urlencoded' }
    })
    .then(response => {
        response.data.username = username;
        localStorage.setItem("user", JSON.stringify(response.data));
        configureAuthHeaders(response.data);
        return response;
    });
};

function configureAuthHeaders(user) {
    if(user) {
        axiosProtectedAPI.defaults.headers.common["Authorization"] = "Bearer " + user.access_token;
        axiosProtectedAPI.interceptors.response.use(response => response, error => {
          if (error.response.status === 401 && !error.config._retry) {
              return refreshToken()
                .then(response => {
                    const currUser = getUser();
                    currUser.refresh_token = response.data.refresh_token;
                    currUser.access_token = response.data.access_token;
                    localStorage.setItem("user", JSON.stringify(currUser));

                    // Set default headers to have authorization the access token as authorization for future requests
                    axiosProtectedAPI.defaults.headers.common["Authorization"] = "Bearer " + response.data.access_token;

                    // Get the original that failed due to 401 and resend it
                    // with the new access token
                    const config = error.config;
                    config._retry = true;
                    config.headers.Authorization = "Bearer " + response.data.access_token;

                    // Resending original request
                    return axiosProtectedAPI.request(config);
                })
            }
            return Promise.reject(error);
        }
    );
    }
}

const logout = () => {
    localStorage.removeItem("user");
};

const refreshToken = () => {
    let currUser = JSON.parse(localStorage.getItem("user"));
    let refreshData = new FormData();
    refreshData.append("grant_type", "refresh_token");
    refreshData.append("refresh_token", currUser.refresh_token);
    return axiosPublicAPI({
        method: 'POST', url: "/oauth/token", data: refreshData,
        headers: { 'Authorization': 'Basic c2RmZnM3ODgyM2Fzc2Y4Nzg4c2RmNzg6c2VjcmV0', 'content-type': 'application/x-www-form-urlencoded' }
    });
};

export default { login, logout, getUser, configureAuthHeaders };