import React, { useContext, useState } from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { axiosPublicAPI } from './http/AxiosInstance';
import AppContext from '../AppContext';


function BookingCancel() {

    const { id } = useParams();
    const { setAlert } = useContext(AppContext);
    const [cancelAPIInvoked, setCancelAPIInvoked] = useState(false);

    function handleCancel() {
        setCancelAPIInvoked(true);
        axiosPublicAPI.delete(`/eventusers/v1/eventuser/${id}`)
            .then(response => setAlert({ variant: "success", heading: "Booking Cancellation", message: "Your booking is cancelled successfully" }))
            .catch(error => setAlert({ variant: "danger", heading: "Booking Cancellation", message: "Sorry, we are unable to cancel your booking. Please try agin later" }));
    }

    return (
        <Container>
            <Row>
                <Col>
                    {!cancelAPIInvoked &&
                        <Alert variant="danger">
                            <Alert.Heading>Booking Cancellation</Alert.Heading>
                            <p>Are you sure you want to cancel the booking?</p>
                            <hr />
                            <div className="d-flex justify-content-end">
                                <Button onClick={handleCancel} variant="outline-danger">Yes</Button>
                            </div>
                        </Alert>
                    }
                </Col>
            </Row>
        </Container>
    );
}
export default BookingCancel;