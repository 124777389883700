import React, { createContext, useState } from "react";
import auth from "./AuthService";

export const AuthContext = createContext();
const AuthContextProvider = (props) => {
    const [user, setUser] = useState(auth.getUser());

    function login(username, password) {
            return auth.login(username, password)
            .then(response => {
                setUser(response.data);
                return response;
            });
    };

    function logout() {
        setUser(null);
        auth.logout();
    };

    auth.configureAuthHeaders(user);

    return (
        <AuthContext.Provider
            value={{
                user,
                login,
                logout,
                setUser
            }}>
            {props.children}
        </AuthContext.Provider>
    );
};
export { AuthContextProvider };